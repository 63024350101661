/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-row">
        <div class="vx-col w-1/3">
          <vx-card class="mb-3">
            <h3 class="font-bold mb-3">Parts List</h3>
            <div class="">
              <vue-draggable-container id="wordBank" class="wordBank">
                <h5 class="dragBlock my-1 cursor-pointer">12GB RAM</h5>
                <h5 class="dragBlock my-1 cursor-pointer">24GB RAM</h5>
                <h5 class="dragBlock my-1 cursor-pointer">SLI Graphics Card</h5>
                <h5 class="dragBlock my-1 cursor-pointer">On Board Video</h5>
                <h5 class="dragBlock my-1 cursor-pointer">Dual Monitor Display</h5>
                <h5 class="dragBlock my-1 cursor-pointer">KVM</h5>
                <h5 class="dragBlock my-1 cursor-pointer">1000W PSW</h5>
                <h5 class="dragBlock my-1 cursor-pointer">750W PSW</h5>
                <h5 class="dragBlock my-1 cursor-pointer">Liquid Cooling</h5>
                <h5 class="dragBlock my-1 cursor-pointer">CPU Fan</h5>
                <h5 class="dragBlock my-1 cursor-pointer">7.1 Surround Card</h5>
                <h5 class="dragBlock my-1 cursor-pointer">External Backup Drive</h5>
              </vue-draggable-container>
            </div>
          </vx-card>
        </div>
        <div class="vx-col w-2/3">
          <vx-card class="mb-3">
            <div class="flex">
              <vs-button type="border" class="font-bold mr-2" :color="currentPage === 0 ? 'success' : 'primary'" @click="currentPage = 0"
                >Gaming PC</vs-button
              >
              <vs-button type="border" class="font-bold ml-2" :color="currentPage === 1 ? 'success' : 'primary'" @click="currentPage = 1"
                >Virtualization Workstation</vs-button
              >
            </div>
          </vx-card>
          <div class="mb-base" :style="{ display: currentPage === 0 ? 'block' : 'none!important' }">
            <div class="PCBackground rounded-lg">
              <div class="grid grid-cols-3 gap-4 p-6">
                <div class="border-white border-solid p-2 rounded bg-primary-gradient">
                  <h1 class="mb-2 font-bold">Power Supply</h1>
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input0"></vue-draggable-container>
                </div>

                <div class="border-white border-solid p-2 rounded bg-primary-gradient">
                  <h1 class="mb-2 font-bold">Cooling</h1>
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input1"></vue-draggable-container>
                </div>

                <div class="border-white border-solid p-2 rounded bg-primary-gradient">
                  <h1 class="mb-2 font-bold">Memory Slot</h1>
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input2"></vue-draggable-container>
                </div>

                <div class="border-white border-solid p-2 rounded bg-primary-gradient">
                  <h1 class="mb-2 font-bold">Graphics Card Slot</h1>
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input3"></vue-draggable-container>
                </div>

                <div class="border-white border-solid p-2 rounded bg-primary-gradient">
                  <h1 class="mb-2 font-bold">Peripherals Slot</h1>
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input4"></vue-draggable-container>
                </div>

                <div class="border-white border-solid p-2 rounded bg-primary-gradient">
                  <h1 class="mb-2 font-bold">External Display</h1>
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input5"></vue-draggable-container>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-base" :style="{ display: currentPage === 1 ? 'block' : 'none!important' }">
            <div class="PCVIRTBackground rounded-lg">
              <div class="grid grid-cols-3 gap-4 p-6">
                <div class="border-white border-solid p-2 rounded bg-primary-gradient">
                  <h1 class="mb-2 font-bold">Power Supply</h1>
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input6"></vue-draggable-container>
                </div>

                <div class="border-white border-solid p-2 rounded bg-primary-gradient">
                  <h1 class="mb-2 font-bold">Cooling</h1>
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input7"></vue-draggable-container>
                </div>

                <div class="border-white border-solid p-2 rounded bg-primary-gradient">
                  <h1 class="mb-2 font-bold">Memory Slot</h1>
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input8"></vue-draggable-container>
                </div>

                <div class="border-white border-solid p-2 rounded bg-primary-gradient">
                  <h1 class="mb-2 font-bold">Graphics Card Slot</h1>
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg"
                    id="input9"
                    v-model="currentPage"
                  ></vue-draggable-container>
                </div>

                <div class="border-white border-solid p-2 rounded bg-primary-gradient">
                  <h1 class="mb-2 font-bold">Peripherals Slot</h1>
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg"
                    id="input10"
                  ></vue-draggable-container>
                </div>

                <div class="border-white border-solid p-2 rounded bg-primary-gradient">
                  <h1 class="mb-2 font-bold">External Display</h1>
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg"
                    id="input11"
                  ></vue-draggable-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      currentPage: 0,
      crimpedSelected: null,
      crimpedCorrect: null,
      toolSelected: null,
      toolCorrect: null,

      //Sim Requirements
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          //this.drag_error()
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      if (this.crimpedSelected === 'incorrect') {
        totalScore++;
        this.crimpedCorrect = true;
      } else {
        this.crimpedCorrect = false;
      }

      if (this.toolSelected === 'cable_certifier') {
        totalScore++;
        this.toolCorrect = true;
      } else {
        this.toolCorrect = false;
      }

      if (document.getElementById('input0').textContent === '1000W PSW') {
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input1').textContent === 'Liquid Cooling') {
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input2').textContent === '12GB RAM') {
        document.getElementById('input2').style.borderColor = '#28C76F';
        document.getElementById('input2').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input3').textContent === 'SLI Graphics Card') {
        document.getElementById('input3').style.borderColor = '#28C76F';
        document.getElementById('input3').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input4').textContent === '7.1 Surround Card') {
        document.getElementById('input4').style.borderColor = '#28C76F';
        document.getElementById('input4').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input4').style.borderColor = '#fd4445';
        document.getElementById('input4').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input5').textContent === 'Dual Monitor Display') {
        document.getElementById('input5').style.borderColor = '#28C76F';
        document.getElementById('input5').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input5').style.borderColor = '#fd4445';
        document.getElementById('input5').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input6').textContent === '750W PSW') {
        document.getElementById('input6').style.borderColor = '#28C76F';
        document.getElementById('input6').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input6').style.borderColor = '#fd4445';
        document.getElementById('input6').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input7').textContent === 'CPU Fan') {
        document.getElementById('input7').style.borderColor = '#28C76F';
        document.getElementById('input7').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input7').style.borderColor = '#fd4445';
        document.getElementById('input7').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input8').textContent === '24GB RAM') {
        document.getElementById('input8').style.borderColor = '#28C76F';
        document.getElementById('input8').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input8').style.borderColor = '#fd4445';
        document.getElementById('input8').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input9').textContent === 'On Board Video') {
        document.getElementById('input9').style.borderColor = '#28C76F';
        document.getElementById('input9').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input9').style.borderColor = '#fd4445';
        document.getElementById('input9').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input10').textContent === 'External Backup Drive') {
        document.getElementById('input10').style.borderColor = '#28C76F';
        document.getElementById('input10').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input10').style.borderColor = '#fd4445';
        document.getElementById('input10').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input11').textContent === 'KVM') {
        document.getElementById('input11').style.borderColor = '#28C76F';
        document.getElementById('input11').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input11').style.borderColor = '#fd4445';
        document.getElementById('input11').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  mounted() {},
  components: {
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.PCBackground {
  min-height: 45vh;
  min-width: 30vw;
  background: #ffffff;
  background-image: linear-gradient(180deg, rgba(41, 41, 41, 0.5), rgba(41, 41, 41, 0.5)),
    url(https://rapidsimulations.com/simulations/6/images/open-air-pc-case.png);
  background-position: 0 0, 50% 50%;
  background-size: auto, contain;
  background-repeat: repeat, no-repeat;
}
.PCVIRTBackground {
  min-height: 45vh;
  min-width: 30vw;
  background: #ffffff;
  background-image: linear-gradient(180deg, rgba(41, 41, 41, 0.5), rgba(41, 41, 41, 0.5)),
    url(https://rapidsimulations.com/simulations/6/images/open-air-pc-case-vm.png);
  background-position: 0 0, 50% 50%;
  background-size: auto, contain;
  background-repeat: repeat, no-repeat;
}
</style>
